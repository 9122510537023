const scrupulosityOCD = {
  intro:
    'The word scrupulous comes from the Latin word scrupulum, which means a small, sharp stone. In ancient' +
    ' Rome, a scruple was a unit of weight measurement. The idea of a small sharp stone represents the persistent' +
    ' pain someone may experience in their everyday life, like the pain of a pebble in someone’s shoe, except they' +
    ' cannot get rid of it.<br/><br/>When people are scrupulous, they want to be exact in the way they conduct' +
    ' themselves either with their <strong>religion, their morals or both</strong>. Their scrupulous mind targets what matters most to them –their morals and religious beliefs. The constant need to perfectly live their morals and religious beliefs leads them to anxiety, uncertainty, and sometimes depression.',
  moralScrupulosityList: [
    'Avoiding anything that triggers the unwanted thoughts, emotions, sensations, and urges that bring up uncertainty regarding their moral behavior.',
    'Seeking reassurance that they are not as bad as their OCD mind says they are (e.g., asking loved ones, reading information, or mentally going back in time to ensure they actually didn’t do anything wrong).',
    'Continually criticizing themselves or inflicting physical punishment on themselves for immoral thoughts, past or future immoral misdeeds, or as “motivation” to be a better person.',
    'Engaging in behaviors that help them remember they did not do anything against their values.',
    'Constantly reviewing their thoughts, feelings, sensations, and urges to make sure they haven’t acted against their values.',
    'Ruminating and rationalizing to find relief from unwanted thoughts, feelings, and sensations.',
    'Excessive “altruistic” behaviors that reassure them that they are good individuals.',
    'Ritualized words and/or behaviors that decrease anxiety, guilt, uncertainty, and other unwanted emotions and sensations related to their moral values.',
    'Constantly reviewing possible scenarios and preparing for the worst.',
    'Repeatedly sacrificing joy and privileges for the sake of others because they don’t think they deserve joy and happiness.',
  ],
  religiousScrupulosityList: [
    'Extreme avoidance of circumstances that may bring about their unwanted thoughts and feelings.',
    'Seeking reassurance by confessing to get reassurance that they wouldn’t or didn’t commit a certain sin, or to find relief from unwanted thoughts and feelings.',
    'Repeating religious rituals until they feel God has listened to and accepted their prayers and/or offerings.',
    'Engaging in behaviors that help them remember they did not do anything sinful.',
    'Continually criticizing or inflicting physical punishment on themselves for immoral thoughts, past or future immoral misdeeds, or as “motivation” to be a better person.',
    'Washing or bathing to “get rid of” unwanted “sinful” thoughts, feelings, and sensations.',
    'Bargaining with God to ensure forgiveness and find relief from intense guilt and other unwanted emotions.',
    'Excessive and repetitive behaviors or thoughts (rumination) about how they can make things better or undo “bad” thoughts and feelings.',
    'Continually asking God for forgiveness.',
    'Constantly reviewing their thoughts and feelings to ensure themselves they haven’t acted in opposition to their religious beliefs.',
    'Sacrificing joy and earthly privileges to show God their devotion and/or repentance.',
  ],
  quote1:
    '<p>“The details seem so important and so necessary –and indeed they are, but not to the point\n' +
    'that they deprive us of peace. Balance is required, and therein lies the real&nbsp;struggle."</p><p>FATHER THOMAS' +
    ' M.' +
    ' SANTA</p>',
  links: [
    {
      title: 'The Scrupulosity OCD Mind is The Human Part of You!',
      link: '/blog/the-scrupulosity-ocd-mind-is-the-human-part-of-you',
    },
    {
      title: 'Scrupulosity OCD and the Sin of Certainty',
      link: '/blog/scrupulosity-ocd-and-the-sin-of-certainty',
    },
    {
      title: 'OCD and the Pervasive Reassurance-Seeking Compulsion',
      link: '/blog/ocd-and-the-pervasive-reassurance-seeking-compulsion',
    },
    {
      title: 'Is It Your Sense Of Integrity or Is It Your OCD?',
      link: '/blog/is-it-your-sense-of-integrity-or-is-it-your-ocd',
    },
    {
      title: 'Scrupulosity OCD — You Have Choices!',
      link: '/blog/scrupulosity-ocd-you-have-choices',
    },
    {
      title: 'Scrupulosity: When OCD Targets Your Religious and Moral Values',
      link: '/blog/scrupulosity-when-ocd-targets-your-religious-and-moral-values',
    },
  ],
};

export default scrupulosityOCD;
