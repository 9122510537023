import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import ReligiousOCD from '../../components/Specialties/components/ReligiousOCD';

export default () => (
  <React.Fragment>
    <SEO
      title="Scrupulosity OCD & Therapy | Mindset Family Therapy"
      description="'Scrupulosity' is a type of OCD that involves obsessions and compulsions that relate to your religion and/or morality. You may worry about thoughts related to blasphemy, having committed a sin, not being morally clean, an inflated sense of responsibility, being punished by God, fear of death, or possibly losing self-control as it relates to your faith and values."
      pathname="/specialties/scrupulosity-ocd"
      article
    />
    <Layout>
      <ReligiousOCD />
    </Layout>
  </React.Fragment>
);
