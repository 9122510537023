import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import BookTeaser from '../../../BookTeaser';

import data from '../../../../data/scrupulosityOCD';

import styles from './religiousOCD.module.scss';

const ReligiousOCD = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498695385/ocSpectrum.jpg';
  const image1 = 'https://res.cloudinary.com/dewd4pral/image/upload/v1569805306/pray2.jpg';

  const moralScrupulosityList = _.map(data.moralScrupulosityList, item => {
    return <li key={_.uniqueId}>{item}</li>;
  });

  const religiousScrupulosityList = _.map(data.religiousScrupulosityList, item => {
    return <li key={_.uniqueId}>{item}</li>;
  });

  const links = _.map(data.links, ({ title, link }, index) => {
    return (
      <li key={index}>
        <Link to={link}>{title}</Link>
      </li>
    );
  });

  return (
    <main>
      <PageHeader pageCategory="Specialties" pageName="Scrupulosity OCD" headerImage={background} />

      <div className={styles.bookWrapper}>
        <BookTeaser bookName="Imperfectly Good" />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Scrupulosity OCD</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro }} />
      </div>

      <QuoteImage image={image1} maxWidth={600} quote={data.quote1} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Moral Scrupulosity</h2>
        <h2 className={styles.headerSmall}>
          What are some indications a person may be struggling with moral scrupulosity?
        </h2>
        <ul>{moralScrupulosityList}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Religious Scrupulosity</h2>
        <h2 className={styles.headerSmall}>
          What are some indications a person may be struggling with religious scrupulosity?
        </h2>
        <p className={styles.copy}>You may find some overlap between moral and religious OCD.</p>
        <br />
        <ul>{religiousScrupulosityList}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Common questions we are asked from potential clients:</h2>

        <h2 className={styles.headerSmall}>
          What is the average age range of clients that come into your office with scrupulosity
          symptoms?
        </h2>
        <p className={styles.copy}>
          Most of the clients we see at our clinic who struggle with scrupulosity are young adults –
          ages ranging between 18 to 26. Many of them are students. Others are getting ready to go
          on a mission – either they have a mission call or have been asked to receive treatment
          before sending in their paper work. We also see clients who have returned early from their
          mission or who completed their mission and discovered they had scrupulosity OCD while
          serving.
        </p>

        <h2 className={styles.headerSmallWithMargin}>
          What are some of the common symptoms you see among your clients with scrupulosity? Have
          you noticed any symptoms that are unique to the LDS faith (i.e., obsession with paying
          tithing)?
        </h2>
        <p className={styles.copy}>
          The driving force with any type of OCD, including scrupulosity, is uncertainty.
          Individuals struggling with scrupulosity can be worried about anything related to their
          religion and morals including tithing. Though tithing may appear to be clear-cut –you
          either pay or you don’t– it{`'`}s not that simple for some people. They may struggle with
          decisions as to what money earned or gifted should be tithed, and question whether they
          have paid their tithing sincerely or not.
          <br />
          <br />
          The most common symptom is obsessing (rumination) about present or past behaviors which
          may have jeopardized their standing before God. Uncertainty drives clients to obsess about
          whether they are good enough or worthy of God’s love and blessings, and to do something to
          feel better about the way they think God views them. Scrupulosity is an equal opportunity
          disorder, so it relates to their own experiences and what is important to them.
        </p>

        <h2 className={styles.headerSmallWithMargin}>
          The best way to understand scrupulosity is by explaining the cycle:
        </h2>
        <h2 className={styles.cycleHeaders}>Trigger:</h2>
        <p className={styles.copy}>
          Any external or internal events (i.e., thoughts, feelings, sensations, and urges) that
          trigger the initial thought.
        </p>

        <h2 className={styles.cycleHeaders}>Obsessions:</h2>
        <p className={styles.copy}>
          The initial thought will bring up additional thoughts (obsessions). Individuals will
          usually get caught up with the content of their thoughts related to different areas. These
          may include: honesty, doing missionary work, praying humbly and sincerely, obeying the
          word of wisdom, the law of chastity, reading and studying scriptures, obsessing about
          having only pure thoughts, avoiding blasphemous thoughts, serving and ministering, going
          to the temple, etc. Individuals struggling with scrupulosity may believe that they are not
          doing enough, and continually question their intentions and behavior.
        </p>

        <h2 className={styles.cycleHeaders}>Feelings and Bodily Sensations:</h2>
        <p className={styles.copy}>
          The obsessions lead clients to have unpleasant feelings such as anxiety, guilt, shame,
          frustration, hopelessness, or depression, among many other feelings. Those feelings in
          turn create unpleasant bodily sensations that lead them to want to do something to rid
          themselves of the unpleasant thoughts, feelings, and sensations.
        </p>

        <h2 className={styles.cycleHeaders}>Compulsions:</h2>
        <p className={styles.copy}>
          Whatever they do to find relief from those internal events are the compulsions. They may
          be mental or external rituals such as reviewing the past and going through every detail in
          their minds (past behavior, thoughts, intentions, and other internal events).
          <br />
          <br />
          Other individuals may pray for long hours (until their prayer feels just right, humble,
          and sincere enough). They may pray constantly as a form of repentance. They may read and
          re-read verses of scripture. If they get distracted by their minds, they go back and read
          again. They may continually confess to their bishops to ensure they are worthy of God’s
          blessings and of partaking of sacred ordinances. They may check labels of food or if at a
          restaurant may ask for all the ingredients their meal contains to ensure they’re keeping
          the word of wisdom.
          <br />
          <br />
          They may wrongly believe their anxiety is the Spirit telling them to repeatedly do
          something such as sharing the gospel with people every single day, which could become
          their focus each day. They may also confuse their anxiety with the Spirit in
          social/romantic situations. For example, some people get “cold feet” as their relationship
          gets more serious. However, when individuals experience scrupulosity, they believe the
          anxiety they are experiencing may be the Spirit sending them a message. They then assume
          that this feeling is a “sign” they should break up. They find relief from their anxiety
          until their next relationship.
          <br />
          <br />
          Reassurance seeking is also a compulsion when individuals review situations in their minds
          to ensure they didn’t do anything wrong to offend God or others. They may experience
          inflated sense of responsibility (hyper-responsibility) in certain areas of their lives –
          where scrupulosity is targeting their fears. They may constantly ask questions to feel
          reassured they haven’t done something immoral and are good enough. They may also read
          about topics related to their scrupulosity to feel reassured they haven’t sinned or
          offended God.
        </p>

        <h2 className={styles.cycleHeaders}>Relief:</h2>
        <p className={styles.copy}>
          After performing compulsive behaviors, they may find some short-term relief from their
          unpleasant internal events (i.e., thoughts, memories, judgments, feelings, and
          sensations). The peace and comfort they experience may be short-lived until the next
          trigger, which gets them stuck in the cycle again.
          <br />
          <br />
          Clients usually don’t recognize that their compulsions (internal or external rituals) to
          find relief from the unpleasant thoughts, feelings, and sensations is for scrupulosity’s
          sake -- not for love of their religion. They don’t realize that the obsessions and
          compulsions strengthen the scrupulosity cycle and ironically weakens their relationship
          with God.
        </p>

        <h2 className={styles.headerSmallWithMargin}>
          What kind of help and support do you offer to clients with OCD and scrupulosity? What has
          been most effective?
        </h2>
        <p className={styles.copy}>
          Cognitive behavioral therapy has been the treatment of preference because the behavioral
          part of CBT consists in helping individuals face their fears (internal events such as
          thoughts, memories, images, feelings, and sensations), and respond differently (behavioral
          change) than they have in the past. This intervention is called Exposure and Response
          Prevention (ERP). This can sound scary for some, but if done with a therapist who
          understands the treatment for OCD and specifically scrupulosity, ERP is seen by
          individuals as an opportunity to respond differently and change their relationship with
          their internal events (i.e., thoughts, feelings, and sensations).
          <br />
          <br />
          At Mindset Family Therapy we provide skills and tools that have been proven to be
          effective from CBT, ERP, and ACT (Acceptance and Commitment Therapy). We help clients
          understand that the goal of the treatment is not to “get rid” of unpleasant internal
          events (i.e., thoughts, memories, images, feelings, sensations, and urges) because they
          are natural events that occur in every human body. There is no delete button in our bodies
          and minds. We cannot control internal events the way we can some external situations.
          <br />
          <br />
          Through ACT (pronounced as the verb to act), clients develop psychological flexibility as
          they practice ERP while using skills to help them become untangled from their thoughts and
          other internal events.
          <br />
          <br />
          Clients recognize there is more to them than their thoughts while applying skills to take
          a step back from the content of their thoughts.
          <br />
          <br />
          They connect with the present instead of being stuck in the scrupulosity cycle.
          <br />
          <br />
          Clients learn the difference between acting on the urge to{' '}
          <i>live their religion perfectly</i> and living their religion as God would want them to.
          <br />
          <br />
          They implement skills to accept what they cannot change in that moment (i.e., thoughts,
          feelings, sensations) and respond differently (ERP).
          <br />
          <br />
          They utilize skills that help them recognize when they are moving closer to their values
          (who and what matters most) or away from them, and to adjust accordingly.
          <br />
          <br />
          With the right kind of treatment, individuals are able to act on life instead of being
          acted upon by their scrupulous mind.
          <br />
          <br />
          At Mindset Family Therapy, we have a passion for helping clients who are suffering with
          scrupulosity OCD!
        </p>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>References</h2>
        <p className={styles.copy}>
          Thomas M. Santa,{' '}
          <i>
            Understanding Scrupulosity: Helpful Answers for Those Who Experience Nagging Questions
            and Doubts
          </i>
          , Second Edition (Liguori, MO: Liguori/Triumph, 2007, 7s.
          <br />
          <br />
          Twohig, M. P., Abramowitz, J. S., Bluett, E. J., Fabricant, L. E., Jacoby, R. J.,
          Morrison, K. L., Smith, B. M. (2015). Exposure therapy for OCD from an acceptance and
          commitment therapy (ACT) framework.{' '}
          <i>Journal of Obsessive-compulsive and Related Disorders</i>, 6, 167–173,{' '}
          <a
            href="http://dx.doi.org/10.1016/j.jocrd.2014.12.007"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://dx.doi.org/10.1016/j.jocrd.2014.12.007
          </a>
          <br />
          <br />
          Twohig MP, Abramowitz JS, Smith BM, et al. Adding acceptance and commitment therapy to
          exposure and response prevention for obsessive-compulsive disorder: A randomized
          controlled trial. <i>Behav Res Ther.</i> 2018;108:1-9. doi:10.1016/j.brat.2018.06.005,{' '}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/29966992/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://pubmed.ncbi.nlm.nih.gov/29966992/
          </a>
          <br />
          <br />
          Twohig, M. P., Abramowitz, J. S., Smith, B.M., Fabricant, L. E., Jacoby, R. J., Morrison
          K. L., Bluett, E. J., Reuman, L., Blake, S. M. , Ledermann, T. (2018). Adding acceptance
          and commitment therapy to exposure and response prevention for obsessive-compulsive
          disorder: A randomized controlled trial. <i>Behaviour Research and Therapy</i>, 108, 1-9,{' '}
          <a
            href="http://jonabram.web.unc.edu/files/2018/08/Twohig-et-al-2018-ACT-ERP.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://jonabram.web.unc.edu/files/2018/08/Twohig-et-al-2018-ACT-ERP.pdf
          </a>
        </p>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Articles on Scrupulosity</h2>
        <ul>{links}</ul>
      </div>
    </main>
  );
};

export default ReligiousOCD;
